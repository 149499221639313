/* footer css */
footer {
    padding: 80px 4.44%;
    background: #041E19;
    position: relative;
    overflow: hidden;
}
footer .top-footer {
    border-bottom: 1px solid #E2EAE9;
    padding: 0 0 64px;
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
}
footer .top-footer .brand-logo {
    margin-right: 150px;
}
footer .top-footer .brand-logo a {
    line-height: 1;
    font-size: 1.5em;
    color: #fff;
}
footer .bottom-footer ul :is(li, a) {
    line-height: 0;
    font-size: 0;
}
footer .bottom-footer ul {
    list-style: none;
    display: flex;
    gap: 0 18px;
    align-items: center;
}
footer .bottom-footer {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
footer .copyright {
    color: #FFF;
    text-align: right;
    font-size: 0.875em;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-right: auto;
}
/* footer .bottom-footer ul li:hover a svg {
  opacity: 0.5;
} */
footer .bottom-footer ul li:hover a svg {
    transition: 0.4s ease all;
}

footer .top-footer ul {
    list-style: none;
    width: 200px;
}
footer .top-footer ul li {
    margin-bottom: 15px;
    line-height: 1;
}
footer .top-footer ul li:last-child {
    margin-bottom: 0;
}
footer .top-footer ul li a {
    color: #FFF;
    font-size: 1em;
    font-weight: 400;
    line-height: .82;
}

footer .elipse {
    width: 1344px;
    height: 406px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -354px;
    border-radius: 1344px;
    background: #19AF96;
    filter: blur(150px);
}

@media (max-width: 1399px) {
    footer .top-footer ul {
        width: 170px;
    }
    footer .top-footer .brand-logo {
        margin-right: 100px;
    }
    footer .col.bottom {
        margin-right: 48px;
    }
}

@media (max-width: 1199px) {
    footer {
        padding: 26px;
    }

    footer .top-footer {
        padding: 0 30px 25px;
        margin-bottom: 15px;
    }

    footer .bottom-footer {
        padding: 0 30px;
    }
    footer .top-footer {
        justify-content: space-between;
    }
    footer .top-footer .col.bottom {
        margin: 0;
    }
}

@media (max-width: 1023px) {
    .footer .brand-logo {
        margin-right: 10%;
    }
    footer .top-footer ul {
        width: 172px;
    }
    .network_wrapper:after {
        height: 500px;
    }
}

@media (max-width: 767px) {
    footer {
        padding: 64px 4.44%;
    }
    footer .top-footer {
        text-align: center;
        padding: 0px 0px 10px;
        margin-bottom: 20px;
        flex-direction: column;
    }
    footer .bottom-footer {
        padding: 0;
        flex-wrap: wrap;
        gap: 20px 0;
        justify-content: flex-start;
    }
    footer .copyright {
        width: 100%;
        text-align: left;
    }
    .footer .brand-logo {
        margin-right: 0;
        width: 100%;
        text-align: left;
        margin-bottom: 32px;
    }
    .footer .top-footer .col {
        width: 50%;
        margin-bottom: 24px;
    }
    .footer .top-footer .col:last-child {
        width: 100%;
    }
    .footer .top-footer .col ul {
        width: 100%;
        text-align: left;
    }
    footer .top-footer ul li a {
        font-size: 0.875em;
        line-height: .8;
    }
    footer .top-footer ul li {
        margin: 0;
        padding: 8px 0;
    }
}
