.newBtn {
    display: flex;
    padding: 8px 5px 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 41px;
    border: 1px solid #00997F;
    background: #FFF;
    color: #00997F;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 150%;
    width: fit-content;
    position: relative; /* Add position relative for absolute positioning */
}

.newBtn.dark {
    background: linear-gradient(98deg, #041E19 41.03%, #19AF96 134.43%);
    color: #FFF;
    border-color: transparent;
}

.newBtn .imgWrap {
    width: 35px;
    height: 32px;
    display: flex;
    overflow: hidden;
}

.newBtn img {
    width: 32px;
    aspect-ratio: 1/1;
    transition: transform 0.25s ease;
}

.newBtn:hover img {
    animation: floatAnimation 1s infinite;
}

@keyframes floatAnimation {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0px);
    }
}
