.bottom_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    padding: 120px 4.44%;
    margin: 0 auto;
}

.bottom_banner .banner-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 80px 48px 120px;
    border-radius: 4px;
    border: 1px solid #99D6CC;
    box-shadow: 0px -11px 30px 0px rgba(33, 174, 149, 0.05);
    backdrop-filter: blur(37.5px);
    position: relative;
}

.bottom_banner .banner-wrap:before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    right: -1px;
    bottom: 6.5%;
    z-index: -1;
    background: linear-gradient(230deg, rgba(255, 255, 255, 1) 46.75%, rgba(229, 246, 243, 0) 95.03%);
}

.bottom_banner .content h3 {
    font-size: 3em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    max-width: 635px;
}

.bottom_banner .content h3 span {
    background: linear-gradient(90deg, #041E19 -12.12%, #19AF96 57.61%);
    -webkit-background-clip: text; /* for WebKit browsers like Chrome and Safari */
    color: transparent;
    display: inline-block;
}

.bottom_banner .btnWrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
}

.bottom_banner .btnWrap .newBtn {
    margin-right: 24px;
}

.bottom_banner .banner-wrap .bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1300px;
    z-index: -1;
}

@media screen and (max-width: 767px) {
    .bottom_banner {
        padding: 60px 4.44%;
    }
    .bottom_banner .banner-wrap {
        padding: 62px 16px;
    }
    .bottom_banner .banner-wrap .content {
        margin-bottom: 100px;
    }
    .bottom_banner .content h3 {
        font-size: 1.75em;
        width: 85%;
    }
    .bottom_banner .banner-wrap .bg {
        width: 200%;
    }
}
