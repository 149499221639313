.features {
    position: relative;
    overflow: hidden;
    padding-top: 100px;
}

.features .feature {
    display: flex;
    padding: 100px 4.44%;
    box-sizing: border-box;
    position: relative;
}

.features .featureWrap {
    width: 100%;
    max-width: 1100px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 64px;
}

.features .feature:nth-child(odd) .featureWrap {
    flex-direction: row;
}

.features .featureWrap .text,
.features .featureWrap .image {
    height: 100%;
    display: flex;
    align-items: center;
}

.features .featureWrap .text:before {
    content: "";
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(229, 246, 243, 0.18) 100%);
    z-index: -1;
}
.features .featureWrap .text {
    width: 50.5%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    padding: 24px 32px;
    height: fit-content;
    border: 1px solid #99D6CC;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(229, 246, 243, 0.18) 100%);
    box-shadow: 0px -11px 30px 0px rgba(33, 174, 149, 0.05);
    backdrop-filter: blur(37.5px);
}
.features .featureWrap .text:before {
    content: "";
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(229, 246, 243, 0.5) 100%);
    z-index: -1;
}
.features .featureWrap .text:after {
    content: "";
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    top: -1px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(229, 246, 243, 0.5) 100%);
    z-index: -1;
}

.features .featureWrap .shadow {
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 100%;
    background: radial-gradient(53.81% 60.34% at 50.04% 50%, #00997F 29.33%, #E5F5F2 100%);
    filter: blur(100px);
    z-index: -2;
    /*top: -8%;*/
}

.features .featureWrap .text .heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    transition: all 1s linear;
    cursor: pointer;
}

.features .featureWrap .text .heading h4 {
    color: #041E19;
    font-size: 2.75em;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 52.8px */
    max-width: calc(100% - 52px);
}

.features .featureWrap .text .heading img {
    width: 40px;
    transform: rotate(90deg);
    transition: all .5s;
    cursor: pointer;
}

.features .featureWrap .text.expanded .heading img {
    transform: rotate(270deg);
}

.features .featureWrap .text .details {
    transition: max-height 1.5s linear, opacity 1s linear, visibility 1s linear;
    color: #364B47;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: normal;
    margin: 30px 0 0;
}

.features .featureWrap .text .learnMore {
    display: flex;
    align-items: center;
    color: #00997F;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-top: 28px;
}

.features .featureWrap .image {
    width: calc(49.5% - 64px);
}

.features .featureWrap .image img {
    height: 100%;
    z-index: 1;
}

.features .featureWrap .text .learnMore img {
    transition: transform .25s ease;
    animation: floatAnimationLearnMore 1s infinite;
}

@keyframes floatAnimationLearnMore {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0px);
    }
}

.features .bg {
    height: calc(100% - 200px);
    max-height: 500px;
    position: absolute;
    z-index: -5;
    top: 100px;
}

.coverVid {
    width: 200vw;
    background: white;
    height: 100px;
    position: absolute;
    transform: translateX(-50%);
    left: 0;
    z-index: -4;
}
.coverVid.top {
    top: -102px
}
.coverVid.bottom {
    top: calc(100% + 2px)
}


/*.features .bg {*/
/*    position: absolute;*/
/*    width: 20%;*/
/*    z-index: -1;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*}*/

.features .bg.left {
    left: 0;
}

.features .bg.right {
    right: 0;
}

.features .heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features .heading .ellipse {
    position: absolute;
    left: 0;
    top: 0;
}

.features .sectionHeader {
    font-size: 3.375em;
    line-height: 1.2;
    color: #000000;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 0 4.44%;
}

.features .sectionHeader:before {
    position: absolute;
    content: "";
    width: 1165px;
    height: 88px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(53.8% 60.34% at 50.04% 50%, rgba(0, 153, 127, 0.25) 29.33%, rgba(229, 245, 242, 0.25) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    filter: blur(50px);
}

@media screen and (max-width: 1199px) {
    .features .feature {
        /*padding: 64px 4.44% 32px;*/
        padding: 130px 4.44% 32px;
        overflow: hidden;
    }
    .features .featureWrap,
    .features .feature:nth-child(odd) .featureWrap {
        flex-direction: column;
    }
    .features .featureWrap {
        height: unset;
    }
    .features .featureWrap .text,
    .features .featureWrap .image {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 18px 16px;
    }
    .features .featureWrap .image img {
        width: 70%;
        height: unset;
    }
    .features .bg {
        top: unset;
        bottom: 50px;
    }
    .features .coverVid {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .features .featureWrap {
        gap: 0;
    }
    .features .sectionHeader {
        font-size: 2em;
    }

    .features .featureWrap .image img {
        width: 100%;
    }

    .features .featureWrap .text .heading h4 {
        font-size: 1.5em;
    }
    .features .featureWrap .text .details {
        font-size: 1em;
        font-weight: 500;
    }
    .features .bg {
        width: 114px;
        height: unset;
        transform: none;
        bottom: 15%;
        top: unset;
    }
    .features .bg.left {
        left: -57px
    }
    .features .bg.right {
        right: -57px;
    }
}
