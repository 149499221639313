
.subscribeWrapper {
    flex: 1;
    max-width: 450px;
    min-width: 348px;
    padding-left: 48px;
    margin-left: auto;
}

.subscribeWrapper .heading {
    color: #FFF;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
}

.subscribeWrapper .formWrap {
    display: flex;
    padding: 4px 4px 4px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 41px;
    border: 1px solid #00997F;
    background: #E2EAE9;
}

.subscribeWrapper .formWrap input,
.subscribeWrapper .formWrap input,
.subscribeWrapper .formWrap input::placeholder {
    flex: 1;
    color: #041E19;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    border: none;
    outline: none;
}

.subscribeWrapper .formWrap button {
    display: flex;
    padding: 5px 10px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 28.5px;
    background: #33AD99;
    color: #FFF;
    font-size: 1em;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
}

@media (max-width: 1279px) {
    .subscribeWrapper {
        max-width: unset;
        min-width: 80%;
        width: 80%;
        margin: 50px auto 0;
    }
    .subscribeWrapper .heading {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .subscribeWrapper {
        margin: 32px 0 40px;
        padding: 24px 0 0;
        width: 100%;

    }
    .subscribeWrapper .heading {
        text-align: left;
    }
    .subscribeWrapper .formWrap input {
        max-width: calc(100% - 100px);
    }
}
