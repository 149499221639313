.network_wrapper {
    position: relative;
    z-index: 8;
    text-align: center;
    padding: 100px 4.44%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.main.network .newBtn {
    margin: 0 auto;
}
.network_wrapper * {
    color: #041E19;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 1.2;
}

.network_wrapper .img {
    max-width: 100%;
    object-fit: contain;
}

.network_wrapper .animatedTexts {
    width: 100%;
    max-width: 870px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
}
.network_wrapper .animatedTexts > div {
    width: 100%;
}
.network_wrapper h3 {
    font-size: 2.8125em;
    font-weight: 500;
    margin: 0 auto 24px;
    background: white;
    position: relative;
    z-index: 1;
}
.network_wrapper p {
    font-size: 1.375em;
    /*margin-bottom: 30px;*/
    letter-spacing: 0.44px;
    max-width: 890px;
    margin: 0 auto 32px;
    color: #364B47;
    background: white;
    position: relative;
    z-index: 1;
}
.network_wrapper h2 {
    margin: 100px auto 64px;
    font-size: 3.75em;
}

.network_wrapper .imgWrap {
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 24px auto 0;
}

.network_wrapper .bg {
    height: auto;
    max-height: 700px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    z-index: -10;
}

.network_wrapper .bg.left {
    left: -5%;
}
.network_wrapper .bg.right {
    right: -5%;
}

@media screen and (max-width: 767px) {
    .network_wrapper h3 {
        font-size: 2.125em;
        line-height: 1.2;
        text-align: left;
        margin: 0;
    }
    .network_wrapper h3 br {
        display: none;
    }
    .network_wrapper p {
        text-align: left;
        margin: 20px 0;
        font-size: 1em;
    }
    .network_wrapper {
        padding: 100px 20px 64px;
    }
    .main.network .newBtn {
        margin: 0;
    }
    .network_wrapper .img {
        margin-top: 50px;
        width: 107%;
        left: -3.5%;
    }
    .network_wrapper .imgWrap {
        margin: 0;
    }
}
