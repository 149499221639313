.connect_blockchain {
    box-sizing: border-box;
}

.connect_blockchain .wrap {
    position: relative;
    width: 100%;
    padding: 64px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*overflow: hidden;*/
    border-radius: 4px;
    border: 1px solid #99D6CC;
    box-shadow: 0px -11px 30px 0px rgba(33, 174, 149, 0.05);
    backdrop-filter: blur(37.5px);

}

.connect_blockchain .wrap:before {
    content: "";
    position: absolute;
    height: 100%;
    left: -1px;
    top: -1px;
    right: -1px;
    z-index: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.77) 0%, rgba(229, 246, 243, 0.25) 100%);
}

.connect_blockchain {
    display: flex;
    align-items: center;
    padding: 100px 4.44%;
    position: relative;
}

.connect_blockchain .inner_wrapper{
    align-items: center;
    justify-content: center;
}
.connect_blockchain .left_text {
    width: calc((100% - 48px) / 2);
    margin-left: 48px;
    max-width: 800px;
    position: relative;
    height: calc(((91.12vw - 144px) / 2 * 0.3765) + 24px + 40px + 50px);
    max-height: 422px;
}

.connect_blockchain img.text {
    width: 90%;
    margin-bottom: 40px;
}

.connect_blockchain .right_video {
    width: calc((100% - 48px) / 2);
}

.connect_blockchain .wrap .elipse {
    width: 88.8%;
    height: 27%;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 5%;
    border-radius: 100%;
    background: radial-gradient(53.81% 60.34% at 50.04% 50%, #00997F 29.33%, #E5F5F2 100%);
    filter: blur(100px);
    z-index: -1;
}

.connect_blockchain .btnWrap {
    display: flex;
    justify-content: flex-start;
}

.connect_blockchain .btnWrap .newBtn {
    margin-right: 24px;
}

.connect_blockchain .mobile {
    display: none;
}

.lottie.mobile,
.connect_blockchain .text.mobile {
    display: none;
}

@media (max-width: 1750px) {
    .connect_blockchain .right_video video {
        max-width: 110%;
        margin-left: -7%;
    }
}
@media (max-width: 1199px) {
    .connect_blockchain {
        padding-bottom: 60px;
    }
}

@media (max-width: 1110px) {

    .connect_blockchain .btnWrap .newBtn {
        font-size: 1rem;
        line-height: 1;
        padding: 4px 5px 4px 16px;
    }
    .connect_blockchain .btnWrap .newBtn .staticArrow {
        width: 30px;
        height: 30px;
        margin-left: 15px;
    }

    .connect_blockchain .btnWrap .newBtn .staticArrow img {
        width: 28px;
    }
}

@media (max-width: 1023px) {

    .connect_blockchain .left_text {
        width: 100%;
        position: relative;
    }
    .connect_blockchain .right_video {
        width: 100%;
    }
    .connect_blockchain .right_video video {
        max-width: 100%;
        margin-left: 0;
    }

    .connect_blockchain .inner_wrapper {
        padding: 0px 40px;
    }
    .connect_blockchain {
        padding: 120px 4.44% 50px;
    }
    .textslider_colin .col > div {
        width: 450px !important;
        height: 270px !important;
    }
    .connect_blockchain .inner_wrapper {
        flex-direction: column-reverse;
    }
    .connect_blockchain .left_text {
        margin-bottom: 50px;
    }
    .connect_blockchain .left_text .details {
        width: 100%;
    }
    .connect_blockchain .right_video video {
        display: none;
    }

    .connect_blockchain .wrap {
        padding: 64px 16px;
        flex-direction: column-reverse;
    }
    .connect_blockchain .right_video {
        width: 90%;
        margin-top: 30px;
        /*margin-bottom: -13%;*/
        /*margin: 50px auto;*/
    }

    .connect_blockchain .left_text {
        width: 100%;
        margin: 0;
        height: unset;
        max-height: unset;
    }
}

@media (max-width: 767px) {
    .connect_blockchain .text {
        display: none;
    }
    .connect_blockchain .text.mobile {
        display: block;
    }
    .connect_blockchain .mobile {
        display: block;
        width: 100%;
    }
    .connect_blockchain .right_video {
        width: 90%;
        /*margin-top: 0;*/
        margin-bottom: -13%;
    }

    .connect_blockchain .inner_wrapper {
        padding: 0px 20px;
    }
    .textslider_colin .next_prev button img {
        max-width: 35px;
    }
    .textslider_colin .next_prev {
        bottom: calc(100% + 20px);
    }
    .connect_blockchain {
        padding-top: 0px;
        margin: 0;
    }
    .textslider_colin .col > div {
        width: 280px !important;
        height: 190px !important;
    }
    .textslider_colin {
        min-height: 140px;
        margin-top: 80px;
    }

    .connect_blockchain img.text {
        width: 100%;
    }
    .connect_blockchain .left_text {
        height: fit-content;
    }
    .lottie {
        display: none;
    }
    .lottie.mobile {
        display: initial;
    }

}
