.onchain_container {
    width: 100%;
    padding: 100px 4.44%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onchain_container .row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.onchain_container h4 {
    color: #041E19;
    text-align: center;
    font-size: 3em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.onchain_container h4 span {
    font-weight: 500;
    color: #041E19;
}

.onchain_container .for {
    font-size: 2em;
    font-weight: 400;
    width: fit-content;
    margin: 0 auto;
    padding: 2px 6px;
    border-radius: 2px;
    border: 1px solid #99D6CC;
    background: #F9FBFB;
    font-family: 'Source Code Pro', monospace;
}

.onchain_container .newBtn {
    width: fit-content;
    margin: 40px auto 0;
}

.onchain_container video {
    width: 75%;
    max-width: 1200px;
    margin-top: 64px;
}

@media screen and (max-width: 1199px) {
    .onchain_container {
        padding-bottom: 0;
    }
    .onchain_container video {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .onchain_container h4 {
        display: grid;
        font-size: 1.75em;
        letter-spacing: 1px;
    }

    .onchain_container h4 span {
        grid-column: span 1;
    }
    .onchain_container .for {
        font-size: 1.5em;
    }
    .onchain_container video {
        width: 140%;
        margin-top: 16px;
    }
}
