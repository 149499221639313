
.banner {
    position: relative;
    overflow: hidden;
    height: 100vh;
    margin:  -86px auto 0;
    aspect-ratio: 16/9;
    max-width: 100%;
}
.banner .textslider {
    position: absolute;
    top: calc(50% + 43px);
    transition: 0.8s linear all;
    opacity: 0;
    transform: translateY(-50%);
    width: 37%;
    left: 4.44%
}

.banner .textslider.mobile {
    display: none;
}

.banner .textslider.active {
    opacity: 1;
    margin-top: -5%;
}
.changebox {
    margin: 0 10px;
    overflow: hidden;
    transition: 0.5s linear all;
    white-space: nowrap;
    position: relative;
    top: 10px;
}
.changebox span {
    display: block;
}
.changebox {
    margin: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -3px;
    overflow: hidden;
    height: 61px;
    position: relative;
    width: 500px;
}
.changebox span {
    transform: translateY(-120px);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 1.2s ease-out all;
}
.changebox span.active {
    transform: translateY(0px);
    opacity: 1;
}
.changebox span.next {
    transform: translateY(120px);
    opacity: 0;
}
.banner .learnmore {
    position: absolute;
    left: 4.44%;
    right: auto;
    width: fit-content;
    text-align: center;
    bottom: 7%;
    margin: 0 auto;
    opacity: 0;
    transition: 0.4s ease all;
    transition-delay: 0.2s;
}

.banner .learnmore span {
    border: 1px solid #99D6CC;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    width: 64px;
    height: 64px;
    box-shadow: 0px 8px 10px rgba(33, 174, 149, 0.10);
}

.banner .learnmore img {
    transform: rotate(90deg);
    width: 80%;
}

@-webkit-keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}
@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}
.banner .learnmore em {
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    color: #00a68a;
    display: block;
}

.banner .count_blog {
    width: 55%;
    position: absolute;
    top: auto;
    bottom: 12%;
    left: 4.44%;
    padding-bottom: 0 !important;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    transition: 0.5s linear all;
    padding-top: 58px;
}

.banner .count_blog h4 {
    color: #464646;
    font-size: 1em;
    /*line-height: 20px;*/
    font-weight: bold;
    position: absolute;
    bottom: 100%;
    text-transform: uppercase;
    word-spacing: 10px;
    font-family: "80-kb";
}
.banner .count_blog .wrap {
    justify-content: flex-start;
}
.banner .count_blog .col,
.banner .count_blog h4 {
    opacity: 0;
    transition: 0.4s linear all;
    transition-delay: 1.2s;
    word-spacing: -2px;
}

.banner .count_blog h3 {
    color: #464646;
    font-size: 1.34375em;
    line-height: 38px;
    font-weight: 400;
    margin-bottom: 6px;
    display: flex;
}
.banner .count_blog p {
    color: #464646;
    font-size: 0.5625em;
    /*line-height: 12px;*/
    font-weight: bold;
    font-family: "80-kb";
}
.banner .count_blog .col:not(:last-child) {
    margin-right: 45px;
}
.banner .animationdiv {
    position: absolute;
    right: 0px;
    top: 26px;
    width: 0%;
    height: 10px;
    padding-top: 1px;
    transition: 0.5s linear all;
    overflow: hidden;
}
.banner .animationdiv span {
    width: calc(100% - 15px);
    top: 4.5px;
    height: 1px;
    background-color: #464646;
    position: absolute;
    left: 15px;
}
.banner .animationdiv::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 3px;
    width: 4px;
    height: 4px;
    background-color: #464646;
    border-radius: 50%;
}

.banner .count_blog.active .animationdiv {
    width: 100%;
}

.banner .img_banner.active {
    opacity: 1;
}

.banner .count_blog.active .col,
.banner .count_blog.active h4 {
    opacity: 1;
}

.banner .count_blog.noCol .col,
.banner .count_blog.noCol h4,
.banner .count_blog.animationdone .col,
.banner .count_blog.animationdone h4 {
    opacity: 0;
}

.banner .count_blog.animationdone .animationdiv {
    position: absolute;
    right: 0px;
    top: 26px;
    width: 0%;
    height: 10px;
    padding-top: 1px;
    transition: 1s linear all;
    overflow: hidden;
}

.banner .count_blog.animationdone {
    opacity: 0;
    pointer-events: none;
}

.banner .learnmore.animationdone {
    opacity: 1;
    -webkit-animation: bounce 2s;
    animation: bounce 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.banner .animationdiv img {
    max-width: calc(100% - 25px);
    margin: 0 auto;
    margin-right: 0;
    display: block;
}


.banner .img_banner {
    /*position: absolute;*/
    width: 61%;
    /*height: 100%;*/
    opacity: 0;
    transition: 0.4s ease all;
    position: absolute;
    top: calc(50% + 43px);
    right: -2%;
    object-fit: contain;
    transform: translateY(-50%);
}


.textslider_colin {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 300px;
    min-height: 100px;
    margin-top: 139px;
}
.textslider_colin .col {
    position: absolute;
    width: 100%;
    padding: 0 !important;
    left: -25px;
    top: -42px;
}
.textslider_colin .col > div {
    overflow: visible !important;
}
.textslider_colin .col h4 {
    font-size: 1.25em;
    font-weight: 300;
    /*line-height: 28px;*/
    color: #fff;
}
.textslider_colin .col a {
    font-size: 1em;
    font-weight: 300;
    /*line-height: 14px;*/
    color: #fff;
    text-decoration: underline;
}
.textslider_colin .next_prev {
    width: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: calc(100% + 27px);
}
.textslider_colin .next_prev button {
    background-color: transparent;
    transform: scale(1);
    transform-origin: center;
}
.textslider_colin .next_prev button img {
    display: block;
}
.textslider_colin .next_prev button:first-child {
    transform: scale(-1);
}


.banner .textslider h1 {
    font-weight: 100;
}
.banner .textslider h1 span {
    font-weight: 300;
}

@media (max-width: 1750px) {
    .banner {
        min-height: 700px;
        overflow: hidden;
    }
    .banner .count_blog {
        bottom: 30px;
    }
    .banner .textslider h1{
        left: 85px;
        bottom: auto;
    }
}

@media (max-width: 1399px) {

    .banner .count_blog .col:not(:last-child) {
        margin-right: 15px;
    }

    .banner {
        min-height: 600px;
    }
    .changebox {
        margin-bottom: -6px;
        height: 64px;
    }
    .banner .count_blog {
        bottom: 60px;
        padding-top: 40px;
    }
    .banner .animationdiv {
        top: 20px;
    }
    .banner .learnmore {
        bottom: 60px;
        margin: 0 auto;
    }
}

@media (max-width: 1279px) {
    .banner .count_blog {
        /*width: 470px;*/
        left: 40px;
    }
    .banner {
        min-height: 500px;
    }

    .changebox {
        margin-bottom: -3px;
        height: 61px;
        width: 440px;
    }

    .banner .count_blog {
        bottom: 10px;
    }
    .banner .learnmore {
        bottom: 20px;
    }
    .textslider_colin .col > div {
        width: 350px !important;
        height: 100% !important;
    }
    .textslider_colin .col {
        left: -15px;
        top: -42px;
    }
}

@media (max-width: 1023px) {
    .banner .count_blog {
        width: 470px;
        left: 20px;
        top: 62px;
        bottom: auto;
    }
    .banner .learnmore {
        left: 0;
        right: 0;
    }

    .banner .count_blog {
        bottom: 0;
        left: 20px;
        top: auto;
    }
    .banner {
        min-height: 870px;
    }
}

@media (max-width: 767px) {
    .banner {
        all: unset;
        min-height: 100svh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column-reverse;
        margin: -50px auto 110px;
        padding-top: 120px;
        overflow: hidden;
    }
    .banner .count_blog{
        display: none;
    }
    .banner .count_blog h3 {
        font-size: 1.25em;
        /*line-height: 30px;*/
        margin-bottom: 5px;
    }
    .banner .count_blog p {
        font-size: 0.625em;
        /*line-height: 13px;*/
    }
    .banner .count_blog {
        width: 270px;
        left: 20px;
        top: 25px;
        bottom: auto;
        padding-bottom: 15px;
    }

    .banner .count_blog .col:not(:last-child) {
        margin-right: 0;
    }
    .banner .count_blog .col {
        width: calc(50% - 20px);
    }
    .banner .count_blog .col:first-child {
        margin-right: 20px !important;
    }
    .banner .count_blog .col:last-child {
        width: 100% !important;
    }
    .banner .animationdiv {
        right: -25px;
        height: 33px;
        padding-top: 1px;
    }
    .banner .learnmore em {
        display: none;
    }
    .banner .learnmore span {
        width: 48px;
        height: 48px;
        margin-bottom: 10px;
    }
    .banner .learnmore span img {
        /*width: 20px;*/
    }

    .changebox {
        height: 40px;
        width: 100%;
        margin: 0;
        top: 7px;
    }
    .banner .textslider {
        display: none;
    }

    .banner .textslider.mobile {
        display: block;
        position: initial;
        transform: none;
        left: unset;
        top: unset;
        width: calc(100% - 40px);
        margin: -5% auto 0;
    }

    .banner .animationdiv {
        top: 8px;
    }
    .banner .count_blog {
        width: 260px;
        top: auto;
        bottom: 12px;
        padding-top: 20px;
    }
    .banner .count_blog h3 {
        font-size: 0.9375em;
        /*line-height: 24px;*/
        margin-bottom: 0;
    }
    .banner .count_blog p {
        font-size: 0.5625em;
        /*line-height: 10px;*/
    }
    .banner .count_blog h4 {
        font-size: 0.0625em;
        /*line-height: 20px;*/
        word-spacing: 4px;
    }
    .banner .img_banner {
        all: unset;
        display: block;
        width: 130%;
        position: initial;
    }
    .banner .learnmore {
        display: none;
        opacity: 1;
        position: absolute;
        left: calc(50% - 19px);
        right: unset;
        bottom: 100px;
        margin: 0;
    }
    .banner .learnmore.mobile {
        display: flex;
        opacity: 1;
        position: initial;

        margin-top: 24px;
    }
}

@media (min-aspect-ratio: 16.05/9) {
    .banner {
        overflow: visible;
        margin-bottom: 250px;
    }
    .banner .textslider {
        left: calc(-1 * (100vw - (100vh / 9 * 16)) / 2 + 4.44vw);
    }
    .banner .count_blog {
        left: calc(-1 * (100vw - (100vh / 9 * 16)) / 2 + 4.44vw);
        width: calc(100vw - (100vh / 9 * 16) / 2);
    }
    .banner .learnmore {
        left: calc(-1 * (100vw - (100vh / 9 * 16)) / 2 + 4.44vw);
    }
    .banner .img_banner.active {
        right: calc(-1 * (100vw - (100vh / 9 * 16)) / 2 + 2vw);
        width: 60%;
    }
}
