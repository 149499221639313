.validator_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 62px;
    padding: 64px 80px;
    max-width: 1440px;
    margin: 0 auto;
    flex: 1 1;
}
.validator_banner .animatedTexts {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 600px;
    gap: 20px;
}

.validator_banner .restaker_graph {
    position: relative;
}

.validator_banner .restaker_graph:before {
    width: calc(100% + 2px);
    height: 50%;
    content: "";
    position: absolute;
    left: -1px;
    bottom: -1px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 30px, rgba(255, 255, 255, 0.00) 71.7%);
}

.validator_banner .restaker_graph img {
    width: 100%;
}

.validator_banner p.heading {
    color: #041E19;
    font-size: 2.9375em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 56.4px */
    background: white;
    z-index: 2;
    position: relative;
    margin: 0;
}

.validator_banner .headingWrap {
    position: relative;
}

.validator_banner p.heading span {
    color: #00a68a;
}
.validator_banner p {
    margin: 0 auto;
    color: #041E19;
    font-size: 1.125em;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
    letter-spacing: 0.44px;
}
.validator_banner .firstPWrap {
    position: relative;
    padding: 24px 0;
}
.validator_banner p.firstP {
    position: relative;
    z-index: 2;
}
.validator_banner p.secondP {
    font-weight: 500;
}
.validator_banner .btnWrap {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
}
.validator_banner .learn_more img {
    margin-left: 14px;
}
.validator_banner .learn_more:hover {
    opacity: 0.7;
}

@media (max-width: 1323px) {
    .validator_banner {
        flex-direction: column;
        padding: 64px 20px;
    }
}

@media (max-width: 767px) {
    .validator_banner h1 {
        font-size: 2em;
        line-height: 1.1;
        margin-bottom: 20px;
    }
    .validator_banner h1 br {
        display: none;
    }
    .validator_banner p,
    .main.video_bg p {
        font-size: 1em;
        line-height: 1.25;
    }
    .validator_banner .learn_more {
        margin-top: 30px;
        font-size: 1.125em;
        line-height: 2.7;
        padding: 0px 7.5px 0px 20px;
    }
    .validator_banner .learn_more img {
        max-width: 31px;
        margin-left: 10px;
    }
    .validator_banner p.heading {
        font-size: 1.5625em;
    }

    .validator_banner .btnWrap {
        flex-direction: column;
        align-items: flex-start;
    }

    .validator_banner .btnWrap .newBtn {
        width: fit-content;
    }

    .validator_banner .animatedTexts {
        width: 100%;
    }
    .validator_banner .restaker_graph {
        width: 100%;
    }

}
