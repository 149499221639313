@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-MediumItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-ThinItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Bold.woff2') format('woff2'),
        url('NeueHaasDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-BlackItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-XXThinItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-XXThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-XThin.woff2') format('woff2'),
        url('NeueHaasDisplay-XThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Black.woff2') format('woff2'),
        url('NeueHaasDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-XXThin.woff2') format('woff2'),
        url('NeueHaasDisplay-XXThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Roman.woff2') format('woff2'),
        url('NeueHaasDisplay-Roman.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-XThinItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-XThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Thin.woff2') format('woff2'),
        url('NeueHaasDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-RomanItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-RomanItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Mediu.woff2') format('woff2'),
        url('NeueHaasDisplay-Mediu.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-Light.woff2') format('woff2'),
        url('NeueHaasDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-BoldItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('NeueHaasDisplay-LightItalic.woff2') format('woff2'),
        url('NeueHaasDisplay-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-SharpBoldItalic.woff2') format('woff2'),
        url('80-kb-SharpBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-SharpItalic.woff2') format('woff2'),
        url('80-kb-SharpItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-Soft.woff2') format('woff2'),
        url('80-kb-Soft.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-Stencil.woff2') format('woff2'),
        url('80-kb-Stencil.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-StencilSoft.woff2') format('woff2'),
        url('80-kb-StencilSoft.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-Grunge.woff2') format('woff2'),
        url('80-kb-Grunge.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-Sharp.woff2') format('woff2'),
        url('80-kb-Sharp.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '80-kb';
    src: url('80-kb-SharpBold.woff2') format('woff2'),
        url('80-kb-SharpBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
